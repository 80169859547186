import React from "react";
import Stack from "react-bootstrap/Stack";
import Col from "react-bootstrap/Col";
import { cleanP } from '@components/utils/CleanedHtml';
import { StaticImage } from "gatsby-plugin-image";
import ImageTransform from "@components/common/ggfx-client/module/components/image-transform";

import { delayItemsFromBottom } from '@components/utils/animations';

import './partnerCard.scss';

const PartnerCard = ({ partner, className, custom }) => {
    const image = partner?.Image?.url;
    const image_alt = partner?.Image?.alternativeText ? partner?.Image?.alternativeText : cleanP(partner?.Title);

    // GGFX
    let processedImages = JSON.stringify({});
    if (partner?.imagetransforms?.Image_Transforms) {
        processedImages = partner?.imagetransforms?.Image_Transforms;
    }

    return(
        <Col
            className={className}
            variants={delayItemsFromBottom}
            custom={custom}
        >
            <Stack
                className="partner-card"
            >
                <div className="partner-card__image__wrapper">
                    {image ?
                        <ImageTransform
                            imagesources={image}
                            renderer="srcSet"
                            imagename="partners.Image.tileImage"
                            attr={{ alt: image_alt, className: "partner-card__image" }}
                            imagetransformresult={processedImages}
                            id={partner.id}
                        />
                    :
                        <StaticImage
                            src="../../../images/no-image.png"
                            // width={440}
                            height={80}
                            formats={["auto", "webp"]}
                            alt=""
                            className="partner-card__image"
                        />
                    }
                </div>
                <div className="divider" />
                <Stack className="gap-4 p-24 partner-card__text">
                    {partner?.Title &&
                        <h3 className="partner-card__name">{partner.Title}</h3>
                    }
                    {partner?.Service &&
                        <span className="partner-card__job-title">{partner.Service}</span>
                    }
                </Stack>
            </Stack>
        </Col>
    )
}

export default PartnerCard