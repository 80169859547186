import React from "react";
import "@src/scss/App.scss";
import Seo from "@components/seo";
import Layout from "@components/layout";
import { graphql } from 'gatsby'
import ModuleSection from '@components/modules/moduleWrapper';
import BannerRender from "@components/modules/Banner/BannerRender";
import PartnerCard from "@components/modules/Partners/partnerCard";
import { Routes } from '@components/routes';
import ApplyPartner from '@components/modules/Forms/ApplyPartner';

const PartnersLandingPages = ({
    // pageContext,
    data,
    // location
}) => {
    const { glstrapi: { page, partners } } = data
    const breadcrumbs = [
        { label: "About", url: `${Routes.AboutPage}` },
        { label: page?.Pagename, url: `${Routes.AboutPage}/${page?.Slug}` }
    ]

    return (
        <Layout popularSearch={page?.Popular_Search} bodyClass="partners-landing">
            <Seo
                title={page?.Metadata?.title ? page.Metadata.title : page.Title}
                description={page?.Metadata?.description ? page.Metadata.description : null}
                // image={page?.Metadata?.image?.url ? page.Metadata.image.url : null}
                image={page?.Metadata?.image?.url_sharp?.childImageSharp ? page.Metadata.image.url_sharp.childImageSharp.resize : null}
            />
            <BannerRender
                page={page}
                breadcrumbs={breadcrumbs}
            />
            <ModuleSection sectionSpacing="py-40 py-md-64 py-lg-80">
                <div className="row row-cols-1 row-cols-md-2 row-cols-xxl-4 gx-0 gy-24 gx-md-16 gy-md-40 gx-lg-40">
                    {partners?.map((partner, i) => {
                        return(
                            <PartnerCard partner={partner} custom={i} key={i} />
                        )
                    })}
                </div>
            </ModuleSection>
            <ApplyPartner
                sectionClass="section-grey section-contact-form"
                title="Apply to become a Partner"
                // text="<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla et tristique nunc, ac rhoncus tellus. Morbi orci orci, tempor eget vestibulum in, elementum at ex. Phasellus nec ligula vitae metus cursus laoreet.</p>"
            />
        </Layout>
    );
}

export default PartnersLandingPages

export const query = graphql`
query partnersLandingQuery($id: ID!) {
    glstrapi {
        page(id: $id, publicationState: LIVE) {
            Pagename
            Title
            Slug
            HeaderTemplate
            Popular_Search
            imagetransforms
            BannerImage {
                ... on GLSTRAPI_ComponentModulesBannerImage {
                    ...ImageBannerFragment
                }
            }
            Metadata {
                title
                description
                image {
                    url
                    url_sharp {
                        childImageSharp {
                            resize(width: 1200) {
                                src
                                height
                                width
                            }
                        }
                    }
                }
            }
        }
        partners(publicationState: LIVE, sort: "Order:DESC") {
            id
            Title
            Service
            Order
            imagetransforms
            Image {
                alternativeText
                url
            }
        }
    }
}
`